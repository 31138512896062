<template>
  <div>
    <el-row>
      <el-col :span="10">
        <el-form label-width="120px" :model="form" :rules="rules" ref="form">
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式类型" prop="storeTypeId">
                <el-select v-model="form.storeTypeId" placeholder="款式类型">
                  <el-option
                    v-for="item in storeStyleTypeArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="款式品牌" prop="storeBrandId">
                <el-select v-model="form.storeBrandId" placeholder="款式品牌" @change="storeBrandChange">
                  <el-option
                    v-for="item in storeBrandArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="系列名称" prop="storeSeriesId">
                <el-select v-model="form.storeSeriesId" placeholder="系列名称" @change="storeSeriesChange">
                  <el-option
                    v-for="item in storeSeriesArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="系列编号" prop="storeSeriesNum">
                <el-input placeholder="系列编号" :value="form.storeSeriesNum" disabled/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式名称" prop="styleName">
                <el-input placeholder="款式名称" v-model="form.styleName"/>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="款式编号" prop="styleNum">
                <el-input placeholder="款式编号" v-model="form.styleNum"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式标签" prop="storeStyleLabels">
                <el-select v-model="form.storeStyleLabels" filterable placeholder="款式标签" multiple>
                  <el-option
                    v-for="item in storeLabelArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="图片类型" prop="imageType">
                <el-select v-model="form.imageType" placeholder="图片类型" clearable>
                  <el-option
                    v-for="item in storeImageTypeArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式价格" prop="salePrice">
                <el-input v-model="form.salePrice" type="number" placeholder="请输入价格"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="建议零售价" prop="suggestSalePrice">
                <el-input v-model="form.suggestSalePrice" type="number" placeholder="请输入建议零售价"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="款式介绍">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  v-model="form.styleInfo">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="适合身形">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  v-model="form.styleFit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="不适合身形">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  v-model="form.styleNoFit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row>
          <el-col :push="17">
            <el-button type="warning" @click="reset">重置</el-button>
            <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="10" :push="1">
        <el-divider content-position="left">上传款式图片</el-divider>
        <el-row style="margin-top: 30px">
          <UploadImages @files="getFiles" :img-size="0"/>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UploadImages from "@/components/image/uploadImages.vue";

export default {
  name: "store-style-add",
  components: {
    UploadImages
  },
  created() {
    this.pageInit()
  },
  data() {
    return {
      form: {
        createDate: new Date(),
        storeTypeId: "",
        storeBrandId: "",
        storeSeriesId: "",
        storeSeriesNum: "",
        styleName: "",
        styleNum: "",
        storeStyleLabels: [],
        storeLabelIdsString: "",
        salePrice: "",
        suggestSalePrice: "",
        imageType: "",
        styleInfo: "",
        styleFit: "",
        styleNoFit: "",
        styleImage: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        storeTypeId: [{required: true, message: '请选择款式类型项目', trigger: 'change'}],
        storeBrandId: [{required: true, message: '请选择款式品牌', trigger: 'change'}],
        storeSeriesId: [{required: true, message: '请选择款式系列', trigger: 'change'}],
        storeSeriesNum: [{required: true, message: '请选择款式系列编号', trigger: 'change'}],
        styleName: [{required: true, message: '输入款式名称', trigger: 'blur'}],
        styleNum: [{required: true, message: '输入款式编号', trigger: 'blur'}],
        storeStyleLabels: [{required: true, message: '请选择款式标签', trigger: 'change'}],
        salePrice: [{required: true, message: '请输入价格', trigger: 'blur'}],
        suggestSalePrice: [{required: true, message: '请输入建议零售价', trigger: 'blur'}],
      },
      storeStyleTypeArray: [],
      storeSeriesArray: [],
      storeBrandArray: [],
      storeLabelArray: [],
      storeImageTypeArray: [],

      imageUploadState: false,
      styleImage: {
        files: [],
        fliesNames: [],
      },
      loading: false,
    }
  },
  watch: {
    'form.storeStyleLabels'(val) {
      this.form.storeLabelIdsString = val.length === 0 ? "" : val.toString();
    },
    'styleImage.files'(val) {
      if (val.length > 0) {
        this.rules.imageType = [{required: true, message: '请选择图片类型', trigger: 'change'}]
        let wareArr = this.storeImageTypeArray.find(k => k.name === '仓库图');
        if (wareArr !== undefined) {
          this.form.imageType = wareArr.value;
        }
      } else {
        delete this.rules.imageType
        this.form.imageType = "";
      }
    },
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryStoreStyleTypeIds().then(response => {
        this.storeStyleTypeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryStoreBrandIds().then(response => {
        this.storeBrandArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryStoreLabelIds().then(response => {
        this.storeLabelArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryStoreStyleImageTypeIds(0).then(response => {
        this.storeImageTypeArray = JSON.parse(response.data.data)
      })
    },
    //品牌改变
    storeBrandChange() {
      //品牌改变时 系列和系列编号都需要清空
      this.form.storeSeriesId = "";
      this.form.storeSeriesNum = "";
      this.queryStoreSeriesIds();
    },
    //系列改变
    storeSeriesChange(value) {
      this.queryStoreSeriesById(value);
    },
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        this.loading = true
        this.uploadStyleImage().then(value => {
          if (!value) {
            this.$message({
              showClose: true,
              message: "图片上传发生错误,请检查后重新上传",
              type: 'error',
              duration: 1500,
            })
            return;
          }
          this.$axios({
            method: "PUT",
            url: "/storeStyle/addStoreStyle",
            data: {
              createDate: new Date(),
              storeTypeId: this.form.storeTypeId,
              storeBrandId: this.form.storeBrandId,
              storeSeriesId: this.form.storeSeriesId,
              styleName: this.form.styleName,
              styleNum: this.form.styleNum,
              imageTypeId: this.form.imageType,
              mainImage: this.styleImage.fliesNames.length > 0 ? this.styleImage.fliesNames[0] : "",
              salePrice: this.form.salePrice,
              suggestSalePrice: this.form.suggestSalePrice,
              publishStatus: 0,
              styleInfo: this.form.styleInfo,
              styleFit: this.form.styleFit,
              styleNoFit: this.form.styleNoFit,
              storeLabelIds: this.form.storeStyleLabels,
              tenantCrop: this.form.tenantCrop,
              imageNames: this.styleImage.fliesNames,
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "款式添加成功" : response.data.msg,
              type: flag ? 'success' : 'error',
              duration: 1000,
            })
            if (flag) {
              this.reset()
              this.styleImage.files = []
              this.styleImage.fliesNames = []
            }
            this.loading = false
          })
        })
      })
    },
    uploadStyleImage: function () {
      return new Promise((resolve, reject) => {
        if (this.styleImage.files < 1) {
          return resolve(true)
        }
        //构建腾讯需要的文件对象
        let buildFlies = this.buildFiles();
        this.$upload.getTemporaryKey(this.$upload.clothesImage).uploadFiles({
          files: buildFlies,
          SliceSize: 1024 * 1024 * 50, //图片大于50M则分块上传
        }, function (err, data) {
          let error = false;
          data.files.forEach(k => {
            //如果不等于null 则说明图片上传有误 则返回true
            error = k.error !== null ? true : error;
          })
          if (err !== null || error) {
            this.$message.error("上传失败,请刷新重试!")
            resolve(false)
          } else {
            resolve(true)
          }
        })

      })
    },
    queryStoreSeriesIds() {
      this.$selectUtils.queryStoreSeriesIds(this.form.storeBrandId).then(response => {
        //清空系列编号
        this.form.storeSeriesNum = "";
        this.storeSeriesArray = JSON.parse(response.data.data)
      })

    },
    queryStoreSeriesById(value) {
      this.$axios({
        method: "get",
        url: "/storeSeries/queryById",
        params: {
          id: value,
        }
      }).then(response => {
        this.form.storeSeriesNum = response.data.data.seriesNum;
      })
    },
    //从子组件获取file
    getFiles(file) {
      this.styleImage.files = file;
    },
    //构建多文件上传时的格式
    buildFiles() {
      let buildFiles = [];
      this.styleImage.files.forEach(k => {
        let arr = k.name.split(".");
        let fileName = this.$md5(arr[0] + new Date()) + "." + arr[arr.length - 1];
        this.styleImage.fliesNames.push(fileName);
        buildFiles.push({
          Bucket: this.$upload.getBucketName(this.$upload.clothesImage), /* 填入您自己的存储桶，必须字段 */
          Region: this.$upload.getRegion(),  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: fileName,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: k.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
        })
      })
      return buildFiles;
    },
  },
}
</script>

<style>

</style>